import React from "react"
import { MDBContainer, MDBRow, MDBCol, MDBCarousel, MDBCarouselInner, MDBCarouselItem } from "mdbreact"
import Lightbox from "react-image-lightbox"
import Layout from "../components/TLCLayout"
import tintImage1 from "../images/services/tint/tint-1.jpg"
import tintImage2 from "../images/services/tint/tint-2.jpg"
import tintImage3 from "../images/services/tint/tint-3.jpg"

var tintRow = {
    margin: '1.5em'
}

var priceText = {
    fontWeight: '800'
}

var imageStyle = {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '3em',
    marginTop: '3em'
}

const images = [
    "/images/sph-ird-specs.jpg"
]

class WindowTintServicesPage extends React.Component{
    constructor(props) {
        super(props)

        this.state = {
            photoIndex: 0,
            isOpen: false
        }
    }

    render() {
        const {photoIndex, isOpen} = this.state

        return (
            <Layout heroTitle="Services - Window Tint" heroImageUrl="./images/hero-dark.jpg">
                <MDBContainer style={{paddingTop: '3em'}}>
                    <MDBRow>
                        <MDBCol><img style={imageStyle} src={tintImage1} width="350px"/></MDBCol>
                        <MDBCol><img style={imageStyle} src={tintImage3} width="350px"/></MDBCol>
                        <MDBCol><img style={imageStyle} src={tintImage2} width="350px"/></MDBCol>
                    </MDBRow>
                    <MDBRow style={tintRow}>
                        {/* <MDBCol md="4">
                            <MDBBox display="flex" justifyContent="center" ><img style={coatingImageStyle} src={bc08Image} /></MDBBox>
                        </MDBCol> */}
                        <MDBCol md="12">
                            <MDBRow>
                                <MDBCol>
                                    <h3>SunTek Carbon</h3>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <p>SunTek Carbon is our entry level privacy film. Carbon has a rich black appearance that will never fade, is metal free to ensure no electronic interference, and comes with a lifetime warranty. SunTek Carbon is available in shades from 5% to 75%.</p>
                                    <p>
                                        Sedans - <span style={priceText}>Starting at $250</span><br />
                                        SUVs - <span style={priceText}>Starting at $275</span>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>     

                    <MDBRow style={tintRow}>
                        {/* <MDBCol md="4">
                            <MDBBox display="flex" justifyContent="center" ><img style={coatingImageStyle} src={bc08Image} /></MDBBox>
                        </MDBCol> */}
                        <MDBCol md="12">
                            <MDBRow>
                                <MDBCol>
                                    <h3>SunTek CIR</h3>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <p>The SunTek CeramicIR (CIRTM) Series is a technologically advanced product line with outstanding, top-performing films. The addition of ceramic technology enhances solar performance and infrared rejection, maintaining a fully non-metal construction for no signal interference with on-board or portable electronic devices which will keep your vehicle cooler even on the hottest days.</p>
                                    <p>Our CeramicIR Series is offered in a range of 8 darkness levels, all non-reflective with an attractive appearance that helps protect against change in color. Passengers and interiors are protected from 99% of UV rays, and vehicle cabin comfort is enhanced with outstanding heat and infrared rejection.</p>
                                    <p>
                                        Sedans - <span style={priceText}>Starting at $400</span><br />
                                        SUVs - <span style={priceText}>Starting at $475</span>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow> 

                    <MDBRow style={tintRow}>
                        {/* <MDBCol md="4">
                            <MDBBox display="flex" justifyContent="center" ><img style={coatingImageStyle} src={bc08Image} /></MDBBox>
                        </MDBCol> */}
                        <MDBCol md="12">
                            <MDBRow>
                                <MDBCol>
                                    <h3>Spectra Photosync IRD</h3>
                                </MDBCol>
                            </MDBRow>
                            <MDBRow>
                                <MDBCol>
                                    <p>The most advanced technology in automotive window film industry comes in our Spectra PhotoSync® IRD (Infrared Radiation Dispersion) Series. Utilizing our patent-pending IRD (Infrared Radiation Dispersion) design, Spectra PhotoSync® IRD is the industry's most advanced IR Nano-Technology window film for vehicles.</p>
                                    <p>IRD - Infrared Radiation Dispersion Technology (Industry 1st), this Nano-Coating is proprietary to Prestige Film Technologies film lines and it allows for the greatest heat rejection and high IRR rejective state. Spectra PhotoSync® IRD is able to reject up to 99% of the IR up to 2200nm and up to 80% of the heat TSER (Total Solar Energy Rejection). Most films in the industry that claims a high IRR specification do so without specifying at which wavelength they test it at. Very often, they only present a high IRR but the performance drops off after 1000nm compared to consistent performance with our films up to 2200nm.</p>
                                    <p>Clear Design - Clarity is never impacted with our window films. Our window films offers exceptional IRR and TSER (Total Solar Energy Rejection) performance without affecting visibility. Vehicles today are interconnected via broadband wireless signals and Spectra PhotoSync® IRD uses our patent-pending Nano-Coating Technology that keeps your vehicle cooler without impacting any wireless signals.</p>
                                    <p>
                                        <a href="#" onClick={() =>
                                            this.setState({ photoIndex: 0, isOpen: true })
                                        }>
                                            Available in 30%, 45%, 55%, and 70%
                                        </a>
                                    </p>
                                    <p>
                                        Sedans - <span style={priceText}>Starting at $650</span><br />
                                        SUVs - <span style={priceText}>Starting at $800</span>
                                    </p>
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                    </MDBRow>      


                    {isOpen && (
                        <Lightbox
                            mainSrc={images[photoIndex]}
                            nextSrc={images[(photoIndex + 1) % images.length]}
                            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
                            imageTitle={photoIndex + 1 + "/" + images.length}
                            onCloseRequest={() => this.setState({ isOpen: false })}
                            onMovePrevRequest={() =>
                                this.setState({
                                photoIndex: (photoIndex + images.length - 1) % images.length
                                })
                            }
                            onMoveNextRequest={() =>
                                this.setState({
                                photoIndex: (photoIndex + 1) % images.length
                                })
                            }
                        />
                )}       
                </MDBContainer>     
            </Layout>
        )
    }

}


export default WindowTintServicesPage